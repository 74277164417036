import React from 'react';
import {connect} from 'react-redux';
import isFunction from 'lodash/isFunction';

import {AppState} from 'store/types';
import * as appActions from 'store/actions';
import {getModalData} from 'store/reducers/appData/selectors';

import globalRegister from 'utils/globalRegister';

import modalsMap from '../modalsMap';

const ModalContainer = ({modalData, onClose}: any): JSX.Element | null => {
    if (!modalData) {
        return null;
    }
    const {name, data, closeBeforeSubmit = true} = modalData;
    const handlersMap = globalRegister.getHandlers();
    const onSubmit = (fields: any): void => {
        if (closeBeforeSubmit) {
            onClose();
        }
        if (isFunction(handlersMap.onSubmit)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            handlersMap.onSubmit(fields);
        }
    };

    const CurrentModal = modalsMap[name] || null;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <CurrentModal data={data} handlers={handlersMap} onSubmit={onSubmit} onClose={onClose} />;
};

export default connect(
    (state: AppState) => {
        return {
            modalData: getModalData(state),
        };
    },
    (dispatch) => {
        return {
            onClose: () => dispatch(appActions.hideModal()),
        };
    },
)(ModalContainer);
