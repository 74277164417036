type Handler = (() => void) | null;
type HandlersMap = {[key: string]: Handler};

interface Register {
    handlers: HandlersMap;
    getHandlers(): HandlersMap;
    setHandlers(handlersMap: HandlersMap): void;
    removeHandlers(): void;
}

const globalRegister: Register = {
    handlers: {},
    getHandlers() {
        return this.handlers;
    },
    setHandlers(handlersMap) {
        this.handlers = handlersMap;
    },
    removeHandlers() {
        this.handlers = {};
    },
};

export default globalRegister;
