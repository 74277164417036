import * as Yup from 'yup';

import {CreateBidFormValues} from 'pages/Bids/types';
import {
    LETTERS,
    LETTERS_REQUIRED_MESSAGE,
    LOCATION_REQUIRED_CITY,
    LOCATION_REQUIRED_MESSAGE,
    LOCATION_REQUIRED_STATE,
} from 'pages/Bids/constants';
import {validateLocationCity, validateLocationState} from 'pages/Bids/utils/validation';

const validation = Yup.object<CreateBidFormValues>({
    pickupCityLine: Yup.string()
        .matches(LETTERS, LETTERS_REQUIRED_MESSAGE)
        .required(LOCATION_REQUIRED_MESSAGE)
        .test({
            message: LOCATION_REQUIRED_CITY,
            test: validateLocationCity,
        })
        .test({
            message: LOCATION_REQUIRED_STATE,
            test: validateLocationState,
        }),
    deliveryCityLine: Yup.string()
        .matches(LETTERS, LETTERS_REQUIRED_MESSAGE)
        .required(LOCATION_REQUIRED_MESSAGE)
        .test({
            message: LOCATION_REQUIRED_CITY,
            test: validateLocationCity,
        })
        .test({
            message: LOCATION_REQUIRED_STATE,
            test: validateLocationState,
        }),
    pickupAmount: Yup.number().positive().typeError('enter pickup stops count'),
    deliveryAmount: Yup.number().positive().typeError('enter delivery stops count'),
    rate: Yup.number().typeError('enter rate'),
});

export default validation;
