import React, {Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';
import {Redirect} from 'react-router';

import LoaderSpinner from 'components/ui/LoaderSpinner';
import useStateUserData from 'hooks/useStateUserData';

import {UserData} from '../core/entities/User/types';
import {userIsAuthenticatedRedir, userIsNotAuthenticatedRedir} from './auth';

const LoginComponent = React.lazy(() => import('../pages/Login'));
const BidsComponent = React.lazy(() => import('../pages/Bids'));
const Users = React.lazy(() => import('../pages/Users'));
const NotFoundPage = React.lazy(() => import('../pages/NotFound'));
const Reports = React.lazy(() => import('../pages/Reports'));

const LoginPage = userIsNotAuthenticatedRedir(LoginComponent);
const BidsPage = userIsAuthenticatedRedir(BidsComponent);
const UsersPage = userIsAuthenticatedRedir(Users);
const ReportsPage = userIsAuthenticatedRedir(Reports);

export default (): JSX.Element => {
    const user: UserData | undefined = useStateUserData();
    return (
        <Suspense fallback={<LoaderSpinner />}>
            <Switch>
                <Route exact path="/" component={BidsPage} />
                <Route exact path="/bids" component={BidsPage} />
                <Route exact path="/login" component={LoginPage} />
                {user?.role === 'admin' ? <Route exact path="/users" component={UsersPage} /> : <Redirect to="/bids" />}
                {user?.role === 'admin' ? (
                    <Route exact path="/reports" component={ReportsPage} />
                ) : (
                    <Redirect to="/bids" />
                )}
                <Route component={NotFoundPage} />
            </Switch>
        </Suspense>
    );
};
