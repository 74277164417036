import {Modal} from 'antd';

export function showErrorModal() {
    return function (dispatch: any) {
        function error(): void {
            Modal.error({
                title: 'Error',
                content: 'An error occurred, please try again later.',
            });
        }
        dispatch(error);
    };
}
