import {PaginationValues} from 'store/types';
import {BidImages, SearchParamsValues} from '../../types';

export const initialBidsPagination: PaginationValues = {
    pageSize: 50,
    currentPage: 1,
    allItemsCount: 0,
    allPagesCount: 0,
};

export const initialConfirmedLinesPagination: PaginationValues = {
    pageSize: 3,
    currentPage: 1,
    allItemsCount: 0,
    allPagesCount: 0,
};

export const initialSearchParams: SearchParamsValues = {
    searchValue: '',
    origin: {
        city: '',
        state: '',
    },
    destination: {
        city: '',
        state: '',
    },
    searchByAddressGroups: false,
};

export const initialBidImages: BidImages = {
    currentPictureIndex: undefined,
    images: [],
};
