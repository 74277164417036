import isPlainObject from 'lodash/isPlainObject';

import config from 'config/index';
import {Attachment} from 'core/entities/Bid/types';

/**
 * Returns the path to the file using its URL and name.
 * @param {object} file
 */

export default (file: Attachment): string => {
    if (!file || !isPlainObject(file)) {
        return '';
    }

    const {url} = file;

    if (!url) {
        return '';
    }

    return `${config.API_URL}/${url}`;
};
