import {useDispatch} from 'react-redux';
import {clearValuesCreateBidModal, setValuesToCreateBidModal} from 'pages/Bids/actions/createBidModalActions';
import {CreateBidFormValues} from 'pages/Bids/types';

interface OwnProps {
    clearValuesCreateBidModal: () => void;
    setValuesToCreateBidModal: (values: CreateBidFormValues) => void;
}

export default function (): OwnProps {
    const dispatch = useDispatch();

    return {
        clearValuesCreateBidModal: () => dispatch(clearValuesCreateBidModal()),
        setValuesToCreateBidModal: (values: CreateBidFormValues) => dispatch(setValuesToCreateBidModal(values)),
    };
}
