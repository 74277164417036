import isEqual from 'lodash/isEqual';
import {Bid} from 'core/entities/Bid/types';
import {UserData} from 'core/entities/User/types';
import {SearchParamsValues} from '../../types';
import {confirmedLinePageSize} from '../../constants';
import {BidsState} from '../index';
import {initialSearchParams} from '../data';
import {PaginationValues} from '../../../../store/types';
import {isBidNotInSearchParams} from './search';
import {isBidNotInFilterParams} from './filter';

export const isEmptySearchParams = (searchParams: SearchParamsValues): boolean =>
    isEqual(searchParams, initialSearchParams);
export const getConfirmedLinesTotalPageCount = (confirmedLineCount: number): number =>
    Math.ceil(confirmedLineCount / confirmedLinePageSize);

export const incrementPagination = (state: BidsState): {allItemsCount: number; allPagesCount: number} => {
    let {
        confirmedLinesPagination: {allItemsCount = 0, allPagesCount = 0},
    } = state;

    const {searchParams} = state;

    if (isEmptySearchParams(searchParams)) {
        allItemsCount += 1;
        allPagesCount = getConfirmedLinesTotalPageCount(allItemsCount);
    }

    return {
        allItemsCount,
        allPagesCount,
    };
};

export const decrementPagination = (state: BidsState): {allItemsCount: number; allPagesCount: number} => {
    let {
        confirmedLinesPagination: {allItemsCount = 1},
    } = state;

    allItemsCount -= 1;

    const allPagesCount = getConfirmedLinesTotalPageCount(allItemsCount);

    return {
        allItemsCount,
        allPagesCount,
    };
};

export const isBidCanBeAdded = (paginationPage: number | undefined): boolean => paginationPage === 1;
export const isNeedAddConfirmedLine = (pagination: PaginationValues): boolean => {
    return pagination.currentPage === 1;
};

export const isCanNotChangeStateFromWebsocketResponse = (
    state: BidsState,
    bid: Bid,
    currentUser: UserData,
    pagePagination?: number,
): boolean =>
    !isBidCanBeAdded(pagePagination) ||
    (state.isSearch && isBidNotInSearchParams(state.searchParams, bid)) ||
    (state.isFiltered && isBidNotInFilterParams(state.filtersParams, bid, currentUser)) ||
    state.isBidFromNotification;
