import {useDispatch} from 'react-redux';
import {UserData} from 'core/entities/User/types';
import {createUser, fetchUserList, deleteUser, updateUser, changeUsersNumberPage} from '../actions/userActions';

interface OwnProps {
    createUser: (value: UserData) => any;
    updateUser: (value: UserData) => any;

    fetchUserList: () => void;
    deleteUser: (id: string) => void;
    changeUsersNumberPage: (currentPage: number) => void;
}

export default function (): OwnProps {
    const dispatch = useDispatch();
    return {
        createUser: (value: UserData) => dispatch(createUser(value)),
        updateUser: (value: UserData) => dispatch(updateUser(value)),
        fetchUserList: () => dispatch(fetchUserList()),
        deleteUser: (id: string) => dispatch(deleteUser(id)),
        changeUsersNumberPage: (currentPage: number) => dispatch(changeUsersNumberPage(currentPage)),
    };
}
