import React from 'react';

import styles from './notifications.module.scss';
import NotificationListHeader from './components/NotificationListHeader';
import NotificationList from './components/NotificaionList';

interface OwnProps {
    isOpen: boolean;
    close: () => void;
}

const Notifications = ({isOpen, close}: OwnProps): JSX.Element | null => {
    return (
        <>
            {isOpen && <div onClick={close} className={styles.mask} />}
            <div className={`${styles.notificationList} ${isOpen ? styles.isOpenList : styles.isCloseList}`}>
                <NotificationListHeader />
                <NotificationList />
            </div>
        </>
    );
};

export default Notifications;
