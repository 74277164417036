import restApi from 'services/api';
import {UserData} from 'core/entities/User/types';
import {AxiosResponse} from 'axios';

export const searchUsers = (search: string): Promise<AxiosResponse> => {
    const url = '/users';
    return restApi.get(url, {
        params: {
            search,
        },
    });
};

export const createUser = (user: UserData): Promise<AxiosResponse> => {
    const url = '/user';
    return restApi.post(url, user);
};

export const updateUser = (user: UserData): Promise<AxiosResponse> => {
    const url = `/user/${user.id}`;
    return restApi.put(url, user);
};

export const deleteUser = (id: string): Promise<AxiosResponse> => {
    const url = `/user/${id}`;
    return restApi.delete(url);
};

export const fetchUserList = (): Promise<AxiosResponse> => {
    const url = '/users';
    return restApi.get(url);
};
