import {Notification} from 'core/entities/Notification/types';

export const RECEIVED_NOTIFICATIONS = 'NOTIFICATIONS_RECEIVED_NOTIFICATIONS' as const;
export const CHANGE_SOUND_NOTIFICATION = 'NOTIFICATIONS_CHANGE_SOUND_NOTIFICATION' as const;
export const REMOVE_NOTIFICATION = 'NOTIFICATIONS_REMOVE_NOTIFICATION' as const;
export const CLEAR_NOTIFICATIONS = 'NOTIFICATIONS_CLEAR_NOTIFICATIONS' as const;

interface ReceivedNotification {
    type: typeof RECEIVED_NOTIFICATIONS;
    payload: {
        notifications: Notification[];
    };
}

interface ChangeSoundNotification {
    type: typeof CHANGE_SOUND_NOTIFICATION;
    payload: {
        isSound: boolean;
    };
}

interface RemoveNotification {
    type: typeof REMOVE_NOTIFICATION;
    payload: {
        id: string;
    };
}

interface ClearNotifications {
    type: typeof CLEAR_NOTIFICATIONS;
}

export type NotificationActionTypes =
    | ReceivedNotification
    | ChangeSoundNotification
    | RemoveNotification
    | ClearNotifications;
