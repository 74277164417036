type States = Record<string, {title: string; code: string}>;

export const USA_STATES: States = {
    AL: {title: 'AL', code: 'AL'},
    AK: {title: 'AK', code: 'AK'},
    AZ: {title: 'AZ', code: 'AZ'},
    AR: {title: 'AR', code: 'AR'},
    CA: {title: 'CA', code: 'CA'},
    CO: {title: 'CO', code: 'CO'},
    CT: {title: 'CT', code: 'CT'},
    DE: {title: 'DE', code: 'DE'},
    DC: {title: 'DC', code: 'DC'},
    FL: {title: 'FL', code: 'FL'},
    GA: {title: 'GA', code: 'GA'},
    HI: {title: 'HI', code: 'HI'},
    ID: {title: 'ID', code: 'ID'},
    IL: {title: 'IL', code: 'IL'},
    IN: {title: 'IN', code: 'IN'},
    IA: {title: 'IA', code: 'IA'},
    KS: {title: 'KS', code: 'KS'},
    KY: {title: 'KY', code: 'KY'},
    LA: {title: 'LA', code: 'LA'},
    ME: {title: 'ME', code: 'ME'},
    MD: {title: 'MD', code: 'MD'},
    MA: {title: 'MA', code: 'MA'},
    MI: {title: 'MI', code: 'MI'},
    MN: {title: 'MN', code: 'MN'},
    MS: {title: 'MS', code: 'MS'},
    MO: {title: 'MO', code: 'MO'},
    MT: {title: 'MT', code: 'MT'},
    NE: {title: 'NE', code: 'NE'},
    NV: {title: 'NV', code: 'NV'},
    NH: {title: 'NH', code: 'NH'},
    NJ: {title: 'NJ', code: 'NJ'},
    NM: {title: 'NM', code: 'NM'},
    NY: {title: 'NY', code: 'NY'},
    NC: {title: 'NC', code: 'NC'},
    ND: {title: 'ND', code: 'ND'},
    OH: {title: 'OH', code: 'OH'},
    OK: {title: 'OK', code: 'OK'},
    OR: {title: 'OR', code: 'OR'},
    PA: {title: 'PA', code: 'PA'},
    RI: {title: 'RI', code: 'RI'},
    SC: {title: 'SC', code: 'SC'},
    SD: {title: 'SD', code: 'SD'},
    TN: {title: 'TN', code: 'TN'},
    TX: {title: 'TX', code: 'TX'},
    UT: {title: 'UT', code: 'UT'},
    VT: {title: 'VT', code: 'VT'},
    VA: {title: 'VA', code: 'VA'},
    WA: {title: 'WA', code: 'WA'},
    WV: {title: 'WV', code: 'WV'},
    WI: {title: 'WI', code: 'WI'},
    WY: {title: 'WY', code: 'WY'},
};

export const CANADA_STATES: States = {
    AB: {title: 'AB', code: 'AB'},
    BC: {title: 'BC', code: 'BC'},
    MB: {title: 'MB', code: 'MB'},
    NB: {title: 'NB', code: 'NB'},
    NL: {title: 'NL', code: 'NL'},
    NS: {title: 'NS', code: 'NS'},
    NT: {title: 'NT', code: 'NT'},
    NU: {title: 'NU', code: 'NU'},
    ON: {title: 'ON', code: 'ON'},
    PE: {title: 'PE', code: 'PE'},
    QC: {title: 'QC', code: 'QC'},
    SK: {title: 'SK', code: 'SK'},
    YT: {title: 'YT', code: 'YT'},
};

export const MEXICO_STATES: States = {
    AG: {title: 'AGS', code: 'AG'},
    BJ: {title: 'BCN', code: 'BJ'},
    BS: {title: 'BCS', code: 'BS'},
    CP: {title: 'CAM', code: 'CP'},
    CH: {title: 'CHP', code: 'CH'},
    CI: {title: 'CHH', code: 'CI'},
    CU: {title: 'COAH', code: 'CU'},
    CL: {title: 'COL', code: 'CL'},
    DF: {title: 'CDMX', code: 'DF'},
    DG: {title: 'DUR', code: 'DG'},
    GJ: {title: 'GTO', code: 'GJ'},
    GR: {title: 'GRO', code: 'GR'},
    HG: {title: 'HGO', code: 'HG'},
    JA: {title: 'JAL', code: 'JA'},
    MH: {title: 'MICH', code: 'MH'},
    MR: {title: 'MOR', code: 'MR'},
    EM: {title: 'MEX', code: 'EM'},
    NA: {title: 'NAY', code: 'NA'},
    NX: {title: 'NLE', code: 'NX'},
    OA: {title: 'OAX', code: 'OA'},
    PU: {title: 'PUE', code: 'PU'},
    QA: {title: 'QRO', code: 'QA'},
    QR: {title: 'QROO', code: 'QR'},
    SL: {title: 'SLP', code: 'SL'},
    SI: {title: 'SIN', code: 'SI'},
    SO: {title: 'SON', code: 'SO'},
    TA: {title: 'TAB', code: 'TA'},
    TM: {title: 'TAMPS', code: 'TM'},
    TL: {title: 'TLA', code: 'TL'},
    VZ: {title: 'VER', code: 'VZ'},
    YC: {title: 'YUC', code: 'YC'},
    ZT: {title: 'ZAC', code: 'ZT'},
};

export const STATES: States = {...MEXICO_STATES, ...USA_STATES, ...CANADA_STATES};
