import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import config from 'config';
import * as actionTypes from 'store/actions/actionTypes';
import {APP_STATE_USER_DATA_FIELD_NAME, APP_STATE_USER_SETTINGS_FIELD_NAME} from 'store/constants';
import appData from 'store/reducers/appData';
import userData from 'store/reducers/userData';
import userSettings from 'store/reducers/userSettings';

import notificationData from 'widgets/Notification/reducers';

import bidsPage from 'pages/Bids/reducers';
import usersPage from 'pages/Users/reducers';

import {getUserSetting} from './userData/selectors';

const appReducer = combineReducers({appData, userData, userSettings, bidsPage, notificationData, usersPage});

const rootReducer = (state: any, action: any): ReturnType<typeof appReducer> => {
    return appReducer(action.type !== actionTypes.USER_LOGGED_OUT ? state : getUserSetting(state), action);
};

const newAppBuildId: string = config.BUILD_HASH;

const persistedReducer = persistReducer(
    {
        key: `bid-rates-app-persists-store-${newAppBuildId}`,
        storage,
        whitelist: [APP_STATE_USER_DATA_FIELD_NAME, APP_STATE_USER_SETTINGS_FIELD_NAME],
    },
    rootReducer,
);

export default persistedReducer;
