import React from 'react';
import Button from 'antd/lib/button';
import useNotificationsWidgetActions from 'hooks/useFindBidByIdActions';

import styles from './linkButton.module.scss';

interface OwnProps {
    bidId: string;
}

const LinkButton = ({bidId}: OwnProps): JSX.Element => {
    const {receivedBidById} = useNotificationsWidgetActions();
    return (
        <>
            <br />
            <Button className={styles.button} type="link" onClick={() => receivedBidById(bidId)}>
                Show the Bid
            </Button>
        </>
    );
};

export default LinkButton;
