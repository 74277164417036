import {ObjectSchema, ValidationError} from 'yup';

interface OwnProps {
    name: string;
    value: string | number;
    setError: (value: string | unknown) => void;
    validateSchema?: ObjectSchema<any>;
    errors?: string;
}

export default function ({validateSchema, errors, name, setError, value}: OwnProps): void {
    if (validateSchema && errors) {
        let message: string | undefined;
        validateSchema
            .validateAt(name, {[name]: value || undefined})
            .catch((error: ValidationError) => {
                message = error.message;
            })
            .finally(() => {
                setError(message);
            });
    }
}
