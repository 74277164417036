import {UserData} from 'core/entities/User/types';

export const LIST_USERS_RECEIVED = 'USERS_PAGE_LIST_USERS_RECEIVED' as const;
export const LIST_USERS_SEARCH = 'LIST_USERS_SEARCH' as const;
export const DELETE_USER = 'USERS_PAGE_DELETE_USER' as const;
export const CHANGE_USERS_PAGE = 'USERS_CHANGE_USERS_PAGE' as const;

interface ListUsersReceived {
    type: typeof LIST_USERS_RECEIVED;
    payload: {users: UserData[]};
}

interface ListUsersSearch {
    type: typeof LIST_USERS_SEARCH;
    payload: {users: UserData[]};
}

interface DeleteUser {
    type: typeof DELETE_USER;
    payload: {id: string};
}

interface ChangeUsersPage {
    type: typeof CHANGE_USERS_PAGE;
    payload: {currentPage: number};
}

export type UsersActionTypes = ListUsersReceived | DeleteUser | ListUsersSearch | ChangeUsersPage;
