import axios from 'axios';

import {store} from 'store';
import {getAppWebSocketID} from 'store/reducers/appData/selectors';
import {isUserLoggedIn} from 'store/reducers/userData/selectors';
import {logout} from 'store/actions/authActions';

const restApi = axios.create({baseURL: '/api'});

// add client websocket id to each request for correct broadcast ws messages
restApi.interceptors.request.use(
    (config) => {
        const state = store.getState();
        config.headers.clientsocketid = getAppWebSocketID(state);
        return config;
    },
    (error) => Promise.reject(error),
);

restApi.interceptors.response.use(
    (response) => response,
    (error) => {
        const HTTP_UNAUTHORIZED_ERROR = 401;
        const isCurrentUserLoggedIn = isUserLoggedIn(store.getState());

        if (error.response.status === HTTP_UNAUTHORIZED_ERROR && isCurrentUserLoggedIn) {
            console.warn('Received unauthorized response, current user logging out ...');
            store.dispatch(logout());
        }
        return Promise.reject(error.response);
    },
);

export default restApi;
