import React from 'react';
import Col from 'antd/lib/grid/col';
import Text from 'antd/lib/typography/Text';

import config from 'config';
import styles from './logo.module.scss';

const Logo = (): JSX.Element => {
    const title = config.isCaravanTheme ? 'Load Info' : 'dispatchland';
    return (
        <Col className={styles.logo} span={4}>
            <Text>{title}</Text>
        </Col>
    );
};

export default Logo;
