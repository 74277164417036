import isString from 'lodash/isString';

import {isCityValid, isStateValid} from 'pages/Bids/utils';

const parseLocation = (location: string): {city: string; state: string} => {
    const [city, state] = location.split(',');
    return {city, state};
};

export const validateLocationCity = (location: any): boolean => {
    if (!isString(location)) {
        return false;
    }
    const {city} = parseLocation(location);
    return isCityValid(city);
};

export const validateLocationState = (location: any): boolean => {
    if (!isString(location)) {
        return false;
    }
    const {state} = parseLocation(location);
    return isStateValid(state);
};
