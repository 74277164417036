import React from 'react';
import {AppSettings, AppSettingsContext} from 'context/appSettings';
import useAppSettingsSetup from 'hooks/appSettings/useAppSettingsSetup';

const AppContext: React.FC<{appSettings?: AppSettings}> = ({children}) => {
    const appSettings = useAppSettingsSetup();
    return <AppSettingsContext.Provider value={appSettings}>{children}</AppSettingsContext.Provider>;
};

export default AppContext;
