import React, {useEffect} from 'react';

import Layout from 'antd/es/layout';

import LoaderSpinner from 'components/ui/LoaderSpinner';

import Header from './components/layout/header/index';
import Main from './components/layout/main/index';
import useIsLoggedInUser from './hooks/useIsLoggedInUser';
import useInitWebsocketConnect from './hooks/useInitWebsocketConnect';
import config from './config';
import getFaviconByCluster from './utils/getFaviconByCluster';
import AppContext from './AppContext';

import './assets/css/custom.scss';

if (config.isCaravanTheme) {
    import(`${__dirname}/assets/css/caravan.custom.scss`);
}

const App = (): JSX.Element => {
    const isUserLoggedIn = useIsLoggedInUser();
    const connectToWebsocket = useInitWebsocketConnect();
    useEffect(() => {
        connectToWebsocket();
    }, [connectToWebsocket]);

    useEffect(() => {
        const setFaviconFromDomainSettings = (): void => {
            const favicon = document.getElementById('favicon');
            if (favicon) {
                // @ts-ignore
                favicon.href = getFaviconByCluster();
            }
        };
        setFaviconFromDomainSettings();
    }, []);
    return (
        <AppContext>
            <div>
                <Layout>
                    {isUserLoggedIn && <Header />}
                    <Main />
                </Layout>
                <LoaderSpinner />
            </div>
        </AppContext>
    );
};

export default App;
