import {PaginationValues} from 'store/types';
import * as websocketTypes from 'store/middlewares/websocket/actionTypes';
import {UserData} from 'core/entities/User/types';
import getPagination from 'utils/getPagination';

import * as types from '../actionTypes';

export interface UsersState {
    users: UserData[];
    pagination: PaginationValues;
    isSearch: boolean;
}

export const defaultState: UsersState = {
    users: [],
    pagination: {
        pageSize: 50,
        currentPage: 1,
        allItemsCount: 0,
        allPagesCount: 0,
    },
    isSearch: false,
};

export default function usersReducer(
    state = defaultState,
    action: types.UsersActionTypes | websocketTypes.WebSocketActionTypes,
): UsersState {
    switch (action.type) {
        case types.LIST_USERS_RECEIVED: {
            const {users} = action.payload;

            return {
                ...state,
                users,
                pagination: getPagination(users),
                isSearch: false,
            };
        }

        case types.LIST_USERS_SEARCH: {
            const {users} = action.payload;

            return {
                ...state,
                users,
                pagination: getPagination(users),
                isSearch: true,
            };
        }

        case types.CHANGE_USERS_PAGE: {
            const {currentPage} = action.payload;

            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    currentPage,
                },
            };
        }

        case websocketTypes.WEB_SOCKET_RECEIVED_REMOVED_USER: {
            const {id: deleteId} = action.payload;

            const users = state.users.filter(({id}: UserData): boolean => id !== deleteId);
            return {
                ...state,
                users,
                pagination: getPagination(users),
            };
        }

        case websocketTypes.WEB_SOCKET_RECEIVED_CREATED_USER: {
            if (state.isSearch) {
                return state;
            }
            const {newUser} = action.payload;

            const users = [newUser, ...state.users];

            return {
                ...state,
                users,
                pagination: getPagination(users),
            };
        }

        case websocketTypes.WEB_SOCKET_RECEIVED_UPDATED_USER: {
            const {updatedUser} = action.payload;

            const users = state.users.reduce((userArray: UserData[], user: UserData) => {
                const userData = user.id === updatedUser.id ? updatedUser : user;
                userArray.push(userData);
                return userArray;
            }, []);

            return {
                ...state,
                users,
            };
        }

        default:
            return state;
    }
}
