import {callBidTooltip, calledBidTooltip} from 'pages/Bids/constants';
import {isCalledBid} from 'pages/Bids/utils';
import {DataForButton} from '../types';

export const getTooltipText = (isDisabled: boolean, isCalled: boolean): string | undefined => {
    if (isDisabled && !isCalled) {
        return callBidTooltip;
    }

    if (isCalled) {
        return calledBidTooltip;
    }

    return undefined;
};

export const getDataForButton = (usersId: string[], userId: string, createdByUserId: string): DataForButton => {
    const isCalled: boolean = isCalledBid(usersId, userId as string);
    const isDisabledButton = userId === createdByUserId || isCalled;
    const title = getTooltipText(isDisabledButton, isCalled);
    const buttonText = isCalled ? 'Called' : 'Call the Bid';

    return {
        isDisabledButton,
        title,
        buttonText,
    };
};
