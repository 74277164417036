import transparentIcon from 'assets/images/transparent.png';
// @ts-ignore
import defaultFavicon from 'assets/images/default_favicon.ico';

import config from 'config';

export default function () {
    if (config.isCaravanTheme) {
        return transparentIcon;
    }

    return defaultFavicon;
}
