import * as actionTypes from 'store/actions/actionTypes';
import {UserData} from 'core/entities/User/types';

type UserAuthActions = actionTypes.UserLoggedIn | actionTypes.UserLoggedOut;
type UserDataState = {
    isUserLoggedIn: boolean;
    user?: UserData;
};

const defaultState: UserDataState = {
    isUserLoggedIn: false,
};

export default function userData(state = defaultState, action: UserAuthActions): UserDataState {
    switch (action.type) {
        case actionTypes.USER_LOGGED_IN: {
            return {
                user: action.payload.user,
                isUserLoggedIn: true,
            };
        }

        case actionTypes.USER_LOGGED_OUT: {
            return {
                isUserLoggedIn: false,
            };
        }

        default:
            return state;
    }
}
