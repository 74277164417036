import React from 'react';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';

import {Bid} from 'core/entities/Bid/types';
import {ConfirmedLine} from 'core/entities/ConfirmedLine/types';

import BidContent from './components/BidContent';
import ModalTitle from './components/ModalTitle';
import ConfirmedLinesContent from './components/ConfirmedLinesContent';

interface OwnProps {
    data: {
        bids: Bid[];
        confirmedLines: ConfirmedLine[];
    };
    onClose(): void;
    handlers: {
        onCallSameBid(): void;
        onCreateSameBid(): void;
    };
}

const SameBidFoundModal = (props: OwnProps): JSX.Element => {
    const {
        data: {bids, confirmedLines},
        handlers: {onCreateSameBid, onCallSameBid},
        onClose,
    } = props;

    return (
        <Modal
            visible={true}
            closable={false}
            title="The Same Route(s) Found"
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="submit" onClick={onCreateSameBid}>
                    Create New Bid
                </Button>,
            ]}
            width={790}
        >
            <div>
                <ModalTitle bids={bids} confirmedLines={confirmedLines} />
                {Boolean(confirmedLines.length) && <ConfirmedLinesContent confirmedLines={confirmedLines} />}
                {Boolean(bids.length) && <BidContent dataSource={bids} handleCallSameBid={onCallSameBid} />}
            </div>
        </Modal>
    );
};

export default SameBidFoundModal;
