import {UserData} from 'core/entities/User/types';

import * as appActionTypes from 'store/actions/actionTypes';
import {isUserLoggedIn, isUserAdmin} from 'store/reducers/userData/selectors';

import * as actions from './actions';
import * as types from './actionTypes';

interface Socket {
    close(...params: any): any;
    on(...params: any): any;
    emit(...params: any): any;
    id: string;
}

const chatAPIWebSockets = (store: any) => (next: any) => {
    const {dispatch} = store;
    let socket: Socket | null = null;

    const initSocketHandlers = (): void => {
        if (!socket) {
            return;
        }

        socket.on('bid_added', ({bid: createdBid}: any) => {
            if (!createdBid) {
                console.warn('Websocket warning: incorrect message with created bid!');
                return;
            }

            dispatch(actions.receivedCreatedBid(createdBid));
        });

        socket.on('note_added', ({bid: createdBidNote}: any) => {
            if (!createdBidNote) {
                console.warn('Websocket warning: incorrect message with created bid note!');
                return;
            }

            dispatch(actions.receivedCreatedBidNote(createdBidNote));
        });

        socket.on('bid_called', ({bid: callBid}: any) => {
            if (!callBid) {
                console.warn('Websocket warning: incorrect message with created bid note!');
                return;
            }

            dispatch(actions.receivedCallBidNote(callBid));
        });

        socket.on('bid_recalled', ({bid: callBid}: any) => {
            if (!callBid) {
                console.warn('Websocket warning: incorrect message with created bid note!');
                return;
            }

            dispatch(actions.receivedCallBidNote(callBid));
        });

        socket.on('bid_called_notification', ({notification}: any) => {
            if (!notification) {
                console.warn('Websocket warning: incorrect message with created bid note!');
                return;
            }

            dispatch(actions.receivedNotification(notification));
        });

        socket.on('bid_recalled_notification', ({notification}: any) => {
            if (!notification) {
                console.warn('Websocket warning: incorrect message with created bid note!');
                return;
            }

            dispatch(actions.receivedNotification(notification));
        });

        socket.on('note_added_notification', ({notification}: any) => {
            if (!notification) {
                console.warn('Websocket warning: incorrect message with created bid note!');
                return;
            }

            dispatch(actions.receivedNotification(notification));
        });
    };

    const initAdminHandlers = (): void => {
        if (!socket) {
            return;
        }

        socket.on('user_added', ({user}: {user: UserData}) => {
            if (!user) {
                console.warn('Websocket warning: incorrect message with created user!');
                return;
            }

            dispatch(actions.receivedCreatedUser(user));
        });

        socket.on('user_removed', ({userId}: {userId: string}) => {
            if (!userId) {
                console.warn('Websocket warning: incorrect message with removed user!');
                return;
            }

            dispatch(actions.receivedRemoveUser(userId));
        });

        socket.on('user_updated', ({user}: {user: UserData}) => {
            if (!user) {
                console.warn('Websocket warning: incorrect message with updated user!');
                return;
            }

            dispatch(actions.receivedUpdateUser(user));
        });
    };

    const initWsConnection = (isAdmin: boolean): void => {
        dispatch(actions.connectToWebSocket())
            .then((socketClient: Socket) => {
                socket = socketClient;
                initSocketHandlers();
                if (isAdmin) {
                    initAdminHandlers();
                }
            })
            .catch((error: any) => {
                console.error('Websocket connection error: ', error);
            });
    };

    const clearCachedSocket = (): void => {
        socket = null;
    };

    return (action: any) => {
        const state = store.getState();

        if (!isUserLoggedIn(state)) {
            return next(action);
        }

        if (action.type === appActionTypes.USER_LOGGED_OUT && socket) {
            socket.close();
            return next(action);
        }
        if (action.type === types.WEB_SOCKET_DISCONNECTED) {
            clearCachedSocket();
            return next(action);
        }

        if (action.type === types.WEB_SOCKET_INIT || action.type === types.WEB_SOCKET_RECONNECT) {
            initWsConnection(isUserAdmin(state));
        }

        return next(action);
    };
};

export default chatAPIWebSockets;
