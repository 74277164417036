import moment, {Moment} from 'moment-timezone';
import {dateFormat, reportDateFormat, timeZone} from 'constants/index';

export const getDateConvertedToDefaultTz = (
    date: Date | string,
    options: {withFormat: boolean} = {withFormat: true},
): string | Moment => {
    const convertedDate = moment.tz(date, 'UTC').clone().tz(timeZone);
    return options.withFormat ? convertedDate.format(dateFormat) : convertedDate;
};

export const getNowWithDefaultTimeZone = (): Moment => moment.tz('UTC').clone().tz(timeZone);

export const convertToStartDay = (date: string): string => {
    const updatedDate = moment(date).tz(timeZone).startOf('day');

    return updatedDate.toISOString();
};
export const convertToEndDay = (date: string): string => {
    const updatedDate = moment(date).tz(timeZone).endOf('day');

    return updatedDate.toISOString();
};

export const convertReportDateToTimezone = (date: string): string => moment.tz(date, timeZone).format(reportDateFormat);
