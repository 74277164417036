import {Address, Bid} from 'core/entities/Bid/types';
import intersection from 'lodash/intersection';
import {SearchParamsValues} from '../../types';

const isStepAddressInSearchParamsByCityLine = (searchAddress: Address, bidStepAddress: Address): boolean =>
    searchAddress.city.toLowerCase() === bidStepAddress.city.toLowerCase() &&
    searchAddress.state.toLowerCase() === bidStepAddress.state.toLowerCase();

const isStepAddressInSearchParamsByState = (searchAddress: Address, bidStepAddress: Address): boolean =>
    searchAddress.state.toLowerCase() === bidStepAddress.state.toLowerCase();

const isSearchByAddressGroups = ({origin, destination}: SearchParamsValues): boolean =>
    Boolean(origin.city && origin.state && destination.city && destination.state);

const isSearchByStates = ({origin, destination}: SearchParamsValues): boolean =>
    Boolean(!origin.city && origin.state && !destination.city && destination.state);

export const getKeyWordsForSearch = (searchValue: string): string[] => {
    const searchIndexes: string[] = searchValue.match(/[^\W][a-zA-Z_'`.-]*/gi) || [];
    return searchIndexes.map((value: string): string => value.toLowerCase()) || [];
};

const getStepKeyWords = ({city, state}: Address): string[] => {
    const cityIndexes: string[] = getKeyWordsForSearch(city);
    const stateIndexes: string[] = getKeyWordsForSearch(state);

    return [...cityIndexes, ...stateIndexes];
};

const isStepInSearchParamsBySearchValueString = ({searchValue}: SearchParamsValues, bid: Bid): boolean => {
    const searchKeyWords = getKeyWordsForSearch(searchValue);
    const pickUpKeyWords = getStepKeyWords(bid.pickupAddress);
    const deliveryKeyWords = getStepKeyWords(bid.deliveryAddress);
    const intersectionByKeyWords = intersection(searchKeyWords, [...pickUpKeyWords, ...deliveryKeyWords]);
    return Boolean(intersectionByKeyWords.length);
};

export const isBidNotInSearchParams = (searchParams: SearchParamsValues, bid: Bid): boolean => {
    if (isSearchByAddressGroups(searchParams)) {
        const {origin, destination} = searchParams;
        return !(
            isStepAddressInSearchParamsByCityLine(origin, bid.pickupAddress) &&
            isStepAddressInSearchParamsByCityLine(destination, bid.deliveryAddress)
        );
    }

    if (isSearchByStates(searchParams)) {
        const {origin, destination} = searchParams;
        return !(
            isStepAddressInSearchParamsByState(origin, bid.pickupAddress) &&
            isStepAddressInSearchParamsByState(destination, bid.deliveryAddress)
        );
    }

    return !isStepInSearchParamsBySearchValueString(searchParams, bid);
};
