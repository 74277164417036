import React from 'react';
import Layout from 'antd/es/layout';

import ModalProvider from 'components/ui/modals/ModalProvider';
import Router from 'routing';

import Notification from 'widgets/Notification';
import styles from './main.module.scss';

const Main = (): JSX.Element => {
    return (
        <Layout.Content role="main" className={styles.wrap}>
            <Router />
            <ModalProvider />
            <Notification />
        </Layout.Content>
    );
};

export default Main;
