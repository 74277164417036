import keyBy from 'lodash/keyBy';
import isString from 'lodash/isString';
import {AppState, PaginationValues} from 'store/types';
import {Address, Attachment} from 'core/entities/Bid/types';

import isEmpty from 'lodash/isEmpty';
import oldStates from './data/oldStates';
import {Filters} from '../types';
import {getBidsCurrentPagination} from '../selectors';
import {fileErrorResponse, largeFileErrorResponse, statusCodeLargeFile} from '../constants';
import {CANADA_STATES, MEXICO_STATES, STATES, USA_STATES} from './data/states';

interface PaginationResponse {
    page: number;
    pageCount: number;
    resultCount: number;
    perPage: number;
}

const getAddressFromString = (values: string): string[] => values.match(/([\w .]+[,]\s*\w{2,3})/g) || [];

export const parseStringToCityState = (value: string): Partial<Address> => {
    const [city, state] = value.trim().split(',');
    return {city, state};
};

export const getPaginationFromResponse = (pagination: PaginationResponse): PaginationValues => ({
    currentPage: pagination.page,
    allPagesCount: pagination.pageCount,
    allItemsCount: pagination.resultCount,
    pageSize: pagination.perPage,
});

export const isImageFile = (file: Attachment): boolean => {
    return file.mimeType?.includes('image');
};

const updateState = (state: string): string => state.toUpperCase().trim();

export const getStateByTitle = (state: string): {code: string; title: string} => {
    const mapKey = keyBy(STATES, 'title');
    return mapKey[updateState(state)];
};

export const replaceOldState = (state: string): string => {
    const replacedState = oldStates[state];
    return replacedState || state;
};

export const getState = (value: string): {title: string; code: string} => {
    if (!value) {
        return {title: '', code: ''};
    }
    const state = updateState(value);
    const replesedOldState = replaceOldState(state);
    return STATES[replesedOldState] || {title: '', code: ''};
};

export const getStateCodeByTitle = (title?: string): string | undefined => {
    if (!title) {
        return;
    }
    return getStateByTitle(title)?.code;
};

export const getStateTitle = (state: string): string | undefined => {
    const stateTitle = getState(state).title;

    if (!isEmpty(stateTitle)) {
        return stateTitle;
    }
};

export const getCityState = (value: string): {city: string; state: string} => {
    const {city = '', state = ''} = parseStringToCityState(value);
    return {city: city.trim(), state: getState(state).code};
};

export const getCityLine = (location: {city: string; state: string}): string => {
    return location.city && location.state ? `${location.city}, ${location.state}` : '';
};

export const getNormalCityLine = (cityLineForUpdate: string): string => {
    const cityLinesForUpdate = getAddressFromString(cityLineForUpdate);
    if (cityLinesForUpdate.length) {
        let newCityLine: string | undefined;

        cityLinesForUpdate.forEach((cityLine: string) => {
            let [city, state] = cityLine.split(',');
            city = city?.trim();
            state = state?.trim().toUpperCase();
            const replacedState = oldStates[state];
            if (state && replacedState) {
                newCityLine = cityLineForUpdate.replace(cityLine.trim(), `${city}, ${replacedState}`);
            }
        });

        if (newCityLine) {
            return newCityLine;
        }
    }

    return cityLineForUpdate;
};

export const isFileErrorResponse = (error: any): boolean => {
    if (error.data.message === fileErrorResponse) {
        return true;
    }

    if (error.status === statusCodeLargeFile) {
        return true;
    }

    if (error.data.message === largeFileErrorResponse) {
        return true;
    }
    return false;
};

export const isNeedFiltered = (filters: Filters): boolean =>
    Boolean(
        filters.pickupState ||
            filters.pickupCity ||
            filters.deliveryState ||
            filters.deliveryCity ||
            filters.rateFrom ||
            filters.rateTo ||
            filters.updatedAtFrom ||
            filters.updatedAtTo ||
            filters.createdAtFrom ||
            filters.createdAtTo ||
            filters.userName ||
            filters.userCompany ||
            filters.userTeam ||
            filters.withNotes,
    );

export const getUpdatedPaginationPage = (page: number, state: AppState): PaginationValues => {
    const pagination: PaginationValues = getBidsCurrentPagination(state);
    return {...pagination, currentPage: page};
};

export const isCityValid = (city: string): boolean => {
    const formattedCity = isString(city) ? city.trim() : '';
    return Boolean(formattedCity);
};

export const isStateValid = (state: string): boolean => {
    const formattedState = isString(state) ? state.trim().toUpperCase() : '';
    if (!formattedState) {
        return false;
    }
    return formattedState in USA_STATES || formattedState in CANADA_STATES || formattedState in MEXICO_STATES;
};

export const isCalledBid = (userIds: string[], userId: string): boolean => userIds.some((id) => id === userId);
