import React from 'react';
import {Button} from 'antd';

interface OwnProps {
    handlePress: () => void;
}

const ButtonLink = ({handlePress}: OwnProps): JSX.Element | null => {
    return <Button onClick={handlePress}>Show the Bid</Button>;
};
export default ButtonLink;
