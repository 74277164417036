import {getState, parseStringToCityState, getStateCodeByTitle} from 'pages/Bids/utils';

export const updateInputValue = (input: string): string => {
    const {state: stateTitle, city} = parseStringToCityState(input);
    if (!stateTitle || !city) {
        return input;
    }
    const stateCode = getStateCodeByTitle(stateTitle);

    if (!stateCode) {
        return input;
    }

    return `${city.trim()}, ${stateCode}`;
};

export const convertAddressToInputValue = (address: string): string => {
    const {state: stateCode, city} = parseStringToCityState(address);
    if (!stateCode || !city) {
        return address;
    }
    const state = getState(stateCode);
    return `${city}, ${state.title}`;
};
