import React from 'react';
import Title from 'antd/lib/typography/Title';
import Form from 'antd/lib/form';
import Modal from 'antd/lib/modal';
import {Field, Formik} from 'formik';
import {FormikHelpers} from 'formik/dist/types';

import FormInput from 'components/ui/form/FormInput';
import RadioInput from 'components/ui/form/RadioInput';

import {UserData} from 'core/entities/User/types';

import {CreateUserInterface} from 'pages/Users/types';
import useUsersActions from 'pages/Users/hooks/useUsersActions';

import {emailExistsErrorMessage, userCodeAndTeamCodeExistsErrorMessage} from '../../../constants';
import styles from './createUser.module.scss';
import validation from './validation';

interface OwnProps {
    onClose: () => void;
    data: UserData;
}

const CreateUserModal = ({onClose, data}: OwnProps): JSX.Element => {
    const initialValues: CreateUserInterface = {
        userCode: data?.userCode || '',
        firstName: data?.firstName || '',
        lastName: data?.lastName || '',
        teamCode: data?.teamCode || '',
        company: data?.company || '',
        role: data?.role || 'dispatcher',
        email: data?.email || '',
        password: undefined,
        confirmPassword: undefined,
    };

    const {createUser, updateUser} = useUsersActions();

    const metaText =
        'the password may include any combination of letters and numbers and must be at least 8 characters';
    const role: string[] = ['dispatcher', 'admin'];

    const closeModal = (resetForm: () => void): void => {
        resetForm();
        onClose();
    };

    const submit = (value: UserData, {resetForm, setFieldError}: FormikHelpers<CreateUserInterface>): void => {
        if (data) {
            updateUser({...value, id: data.id})
                .then(() => closeModal(resetForm))
                .catch((error: any) => {
                    if (error.data.message === userCodeAndTeamCodeExistsErrorMessage) {
                        setFieldError('userCode', 'that user code is already taken, try another');
                    }
                });
            return;
        }
        createUser(value)
            .then(() => closeModal(resetForm))
            .catch((error: any) => {
                if (error.data.message === userCodeAndTeamCodeExistsErrorMessage) {
                    setFieldError('userCode', 'that user code is already taken, try another');
                } else if (error.data.message === emailExistsErrorMessage) {
                    setFieldError('email', emailExistsErrorMessage);
                }
            });
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={submit}
            validationSchema={() => validation(Boolean(data))}
            validateOnChange={false}
        >
            {({handleSubmit, resetForm}) => (
                <Modal
                    className={styles.modal}
                    visible={true}
                    closable={false}
                    title="Create New User"
                    onOk={() => handleSubmit()}
                    onCancel={() => closeModal(resetForm)}
                    okText="Save"
                    okButtonProps={{className: 'secondary-button'}}
                >
                    <Form>
                        <Title level={4}>Account Information:</Title>
                        <Field
                            name="userCode"
                            type="input"
                            label="User Code"
                            placeholder="enter user code"
                            as={FormInput}
                            required={true}
                            isNeedSpaceIgnore={true}
                            maxLength={5}
                            className={styles.code_field}
                            isNeedIgnoreSpecialSymbols={true}
                        />
                        <Field
                            name="firstName"
                            type="input"
                            label="First Name"
                            placeholder="enter first name"
                            as={FormInput}
                            required={true}
                            maxLength={30}
                        />
                        <Field
                            name="lastName"
                            type="input"
                            label="Last Name"
                            placeholder="enter last name"
                            as={FormInput}
                            required={true}
                            maxLength={30}
                        />
                        <Field
                            name="teamCode"
                            type="input"
                            label="Team Code"
                            placeholder="enter team code"
                            as={FormInput}
                            required={true}
                            className={styles.code_field}
                            isNeedIgnoreSpecialSymbols={true}
                        />
                        <Field
                            name="company"
                            type="input"
                            label="Company Name"
                            placeholder="enter company name"
                            as={FormInput}
                            required={true}
                            maxLength={30}
                        />

                        <Field value={role} name="role" type="input" label="Choose permission" as={RadioInput} />

                        <Title level={4}>Login Information:</Title>

                        <Field
                            name="email"
                            type="input"
                            placeholder="enter email"
                            isNeedSpaceIgnore={true}
                            label="User Email"
                            as={FormInput}
                            required={true}
                        />
                        <Field
                            name="password"
                            type="password"
                            placeholder="enter password"
                            isNeedSpaceIgnore={true}
                            label="Password"
                            maxLength={16}
                            as={FormInput}
                            required={true}
                            metaText={metaText}
                        />
                        <Field
                            name="confirmPassword"
                            type="password"
                            placeholder="confirm password"
                            isNeedSpaceIgnore={true}
                            label="Confirm Password"
                            maxLength={16}
                            as={FormInput}
                            required={true}
                        />
                    </Form>
                </Modal>
            )}
        </Formik>
    );
};

export default CreateUserModal;
