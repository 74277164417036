import React from 'react';
import Text from 'antd/lib/typography/Text';
import {ConfirmedLine} from 'core/entities/ConfirmedLine/types';
import Table from 'antd/lib/table';

interface OwnProps {
    confirmedLines: ConfirmedLine[];
}

const ConfirmedLinesContent = ({confirmedLines}: OwnProps): JSX.Element => {
    const columns = [
        {
            title: 'FROM',
            dataIndex: 'pickupAddress',
            key: 'pickupAddress',
            render: (pickupAddress: string) => <Text>{pickupAddress}</Text>,
            width: 120,
        },
        {
            title: 'TO',
            dataIndex: 'deliveryAddress',
            key: 'deliveryAddress',
            render: (deliveryAddress: string) => <Text>{deliveryAddress}</Text>,
            width: 120,
        },
        {
            title: 'MILES',
            dataIndex: 'miles',
            key: 'miles',
            render: (miles?: number): JSX.Element => <Text>{miles || ''}</Text>,
            width: 70,
        },
        {
            title: 'RATE',
            dataIndex: 'rate',
            key: 'rate',
            render: (rate: number): JSX.Element => <Text>${rate}</Text>,
            width: 70,
        },
        {
            title: 'COMPANY',
            dataIndex: 'company',
            key: 'company',
            render: (company: string | undefined = ''): JSX.Element => <Text>{company}</Text>,
            width: 100,
        },
        {
            title: 'DETAILS',
            dataIndex: 'details',
            key: 'details',
            render: (details: string | undefined = ''): JSX.Element => <Text>{details}</Text>,
            width: 100,
        },
    ];
    return (
        <div>
            <Text>{confirmedLines.length} the same lane(s)</Text>

            <Table
                className="custom-table"
                rowKey={(confirmedLine: ConfirmedLine): string => confirmedLine.id as string}
                bordered={true}
                dataSource={confirmedLines}
                columns={columns}
                pagination={false}
                scroll={{
                    y: 153,
                }}
            />
        </div>
    );
};

export default ConfirmedLinesContent;
