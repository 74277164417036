import moment from 'moment';
import {Bid, BidUserData} from 'core/entities/Bid/types';
import {UserData} from 'core/entities/User/types';
import {Filters} from '../../types';

const getValueBetweenBidTimeAndFilterTime = (bidTime: Date, filterTime: string): number => {
    const filterTimeInDateFormat = new Date(filterTime);
    const bidTimeInMilliseconds = parseInt(moment(bidTime).format('x'), 10);
    const filterTimeInMilliseconds = parseInt(moment(filterTimeInDateFormat).format('x'), 10);
    return bidTimeInMilliseconds - filterTimeInMilliseconds;
};

const isUserNameOrCodeIsNotInFilterParams = (user: BidUserData, filterUserName: string): boolean => {
    const lowerCaseFilterUserName = filterUserName.toLowerCase();
    const fullUserName = `${user.firstName?.toLowerCase()} ${user.lastName?.toLowerCase()}`;
    return !fullUserName.includes(lowerCaseFilterUserName);
};

const isNeedWatchTeemCode = (bidCreateUserInfo: BidUserData, user: UserData): boolean =>
    (user.company === bidCreateUserInfo.company && user.teamCode !== bidCreateUserInfo.userCode) ||
    user.company !== bidCreateUserInfo.company;

const isNeedWatchCompany = (bidCreateUserInfo: BidUserData, user: UserData): boolean =>
    user.company === bidCreateUserInfo.company;

export const isBidNotInFilterParams = (filters: Filters, bid: Bid, user: UserData): boolean => {
    let isNotInSearchParams = true;
    if (filters.pickupCity) {
        isNotInSearchParams = filters.pickupCity.toLowerCase() !== bid.pickupAddress.city.toLowerCase();
        if (isNotInSearchParams) {
            return isNotInSearchParams;
        }
    }

    if (filters.pickupState) {
        isNotInSearchParams = filters.pickupState.toLowerCase() !== bid.pickupAddress.state.toLowerCase();
        if (isNotInSearchParams) {
            return isNotInSearchParams;
        }
    }

    if (filters.deliveryCity) {
        isNotInSearchParams = filters.deliveryCity.toLowerCase() !== bid.deliveryAddress.city.toLowerCase();
        if (isNotInSearchParams) {
            return isNotInSearchParams;
        }
    }

    if (filters.deliveryState) {
        isNotInSearchParams = filters.deliveryState.toLowerCase() !== bid.deliveryAddress.state.toLowerCase();
        if (isNotInSearchParams) {
            return isNotInSearchParams;
        }
    }

    if (filters.rateFrom) {
        isNotInSearchParams = filters.rateFrom > bid.rate;
        if (isNotInSearchParams) {
            return isNotInSearchParams;
        }
    }

    if (filters.rateTo) {
        isNotInSearchParams = filters.rateTo < bid.rate;
        if (isNotInSearchParams) {
            return isNotInSearchParams;
        }
    }

    if (filters.withNotes) {
        isNotInSearchParams = !bid.notes?.length;
        if (isNotInSearchParams) {
            return isNotInSearchParams;
        }
    }

    if (filters.withNotes === false) {
        isNotInSearchParams = false;
    }

    if (filters.createdAtFrom) {
        const timeBetweenBidTimeAndFilterTime = getValueBetweenBidTimeAndFilterTime(
            bid.createdAt,
            filters.createdAtFrom,
        );
        isNotInSearchParams = timeBetweenBidTimeAndFilterTime < 0;
        if (isNotInSearchParams) {
            return isNotInSearchParams;
        }
    }

    if (filters.createdAtTo) {
        const timeBetweenBidTimeAndFilterTime = getValueBetweenBidTimeAndFilterTime(bid.createdAt, filters.createdAtTo);
        isNotInSearchParams = timeBetweenBidTimeAndFilterTime > 0;
        if (isNotInSearchParams) {
            return isNotInSearchParams;
        }
    }

    if (filters.updatedAtFrom) {
        const timeBetweenBidTimeAndFilterTime =
            bid.updatedAt && getValueBetweenBidTimeAndFilterTime(bid.updatedAt, filters.updatedAtFrom);
        isNotInSearchParams = timeBetweenBidTimeAndFilterTime ? timeBetweenBidTimeAndFilterTime < 0 : true;
        if (isNotInSearchParams) {
            return isNotInSearchParams;
        }
    }

    if (filters.updatedAtTo) {
        const timeBetweenBidTimeAndFilterTime =
            bid.updatedAt && getValueBetweenBidTimeAndFilterTime(bid.updatedAt, filters.updatedAtTo);
        isNotInSearchParams = timeBetweenBidTimeAndFilterTime ? timeBetweenBidTimeAndFilterTime > 0 : true;
        if (isNotInSearchParams) {
            return isNotInSearchParams;
        }
    }

    if (filters.userCompany && isNeedWatchCompany(bid.createdBy, user)) {
        isNotInSearchParams = !bid.createdBy.company?.toLowerCase().includes(filters.userCompany.toLowerCase());
        if (isNotInSearchParams) {
            return isNotInSearchParams;
        }
    }

    if (filters.userTeam && isNeedWatchTeemCode(bid.createdBy, user)) {
        isNotInSearchParams = !bid.createdBy.teamCode?.toLowerCase().includes(filters.userTeam.toLowerCase());
        if (isNotInSearchParams) {
            return isNotInSearchParams;
        }
    }

    if (filters.userName && bid.createdBy.firstName && bid.createdBy.lastName) {
        isNotInSearchParams = isUserNameOrCodeIsNotInFilterParams(bid.createdBy, filters.userName);
        if (isNotInSearchParams) {
            return isNotInSearchParams;
        }
    }

    if (filters.userName && bid.createdBy.userCode && bid.createdBy.company !== user.company) {
        isNotInSearchParams = !bid.createdBy.userCode.toLowerCase().includes(filters.userName.toLowerCase());
        if (isNotInSearchParams) {
            return isNotInSearchParams;
        }
    }
    return isNotInSearchParams;
};
