import {AppState, UserSettings} from 'store/types';
import {UserData} from 'core/entities/User/types';

export const getCurrentUser = (state: AppState): UserData | undefined => state.userData.user;

export const isUserLoggedIn = (state: AppState): boolean => {
    return state?.userData?.isUserLoggedIn;
};

export const getUserSetting = (state: AppState): {userSettings: UserSettings} => {
    const {userSettings} = state;

    return {
        userSettings: {
            bidEmail: userSettings.bidEmail,
            bidPassword: userSettings.bidPassword,
        },
    };
};

export const isUserAdmin = (state: AppState): boolean => {
    return state?.userData?.user?.role === 'admin';
};
