import {applyMiddleware, compose, createStore} from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import {persistStore} from 'redux-persist';

import config from 'config';
import rootReducer from 'store/reducers';
import websocket from 'store/middlewares/websocket';

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const getMiddlewares = () => {
    return config.IS_DEV_MODE ? applyMiddleware(thunk, logger, websocket) : applyMiddleware(thunk, websocket);
};
const store = createStore(rootReducer, composeEnhancers(getMiddlewares()));
const persistor = persistStore(store);

export {store, persistor};
