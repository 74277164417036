import * as types from '../actionTypes';
import * as requests from './requests';

export function fetchNotifications() {
    return function (dispatch: any) {
        requests
            .fetchNotifications()
            .then((response) => response.data)
            .then(({notifications}) => {
                dispatch({
                    type: types.RECEIVED_NOTIFICATIONS,
                    payload: {
                        notifications,
                    },
                });
            })
            .catch((err) => console.log(err));
    };
}

export function handleSound(isSound: boolean) {
    return function (dispatch: any) {
        dispatch({
            type: types.CHANGE_SOUND_NOTIFICATION,
            payload: {
                isSound,
            },
        });
    };
}

export function removeNotification(id: string) {
    return function (dispatch: any) {
        requests
            .removeNotification(id)
            .then(() => {
                dispatch({
                    type: types.REMOVE_NOTIFICATION,
                    payload: {
                        id,
                    },
                });
            })
            .catch((err) => console.log(err));
    };
}

export function clearNotifications() {
    return function (dispatch: any) {
        requests
            .clearNotifications()
            .then(() => {
                dispatch({
                    type: types.CLEAR_NOTIFICATIONS,
                });
            })
            .catch((err) => console.log(err));
    };
}
