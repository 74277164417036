import React from 'react';
import Tooltip from 'antd/lib/tooltip';
import Button from 'antd/lib/button';
import useStateUserId from 'pages/Bids/hooks/useStateUserId';
import {BidUserData} from 'core/entities/Bid/types';
import {getUsersId} from 'pages/Bids/components/BidList/utils';
import {DataForButton} from 'pages/Bids/components/modals/SameBidFoundModal/types';
import {getDataForButton} from 'pages/Bids/components/modals/SameBidFoundModal/utils';

import styles from './callButton.module.scss';

interface OwnProps {
    createdByUserId: string;
    onCallSameBid: (value: string) => void;
    bidId: string;
    calledBy: BidUserData[] | undefined;
}

const CallButton = ({bidId, calledBy, createdByUserId, onCallSameBid}: OwnProps): JSX.Element => {
    const usersId = getUsersId(calledBy);
    const userId = useStateUserId();
    const {title, isDisabledButton, buttonText}: DataForButton = getDataForButton(
        usersId,
        userId as string,
        createdByUserId,
    );
    return (
        <Tooltip title={title} color="orange" trigger="hover">
            <Button
                type="primary"
                disabled={isDisabledButton}
                className={`secondary-button ${styles.call_button}`}
                onClick={() => onCallSameBid(bidId)}
            >
                {buttonText}
            </Button>
        </Tooltip>
    );
};

export default CallButton;
