import io from 'socket.io-client';

import {UserData} from 'core/entities/User/types';
import {Notification} from 'core/entities/Notification/types';
import {Bid} from 'core/entities/Bid/types';

import {getCurrentUser} from 'store/reducers/userData/selectors';
import * as types from './actionTypes';
import {AppState} from '../../types';

export function init() {
    return {
        type: types.WEB_SOCKET_INIT,
    };
}

export function reconnect() {
    return {
        type: types.WEB_SOCKET_RECONNECT,
    };
}

export function connected({socket}: {socket: any}) {
    return {
        type: types.WEB_SOCKET_CONNECTED,
        payload: {socketID: socket.id},
    };
}

export function disconnected() {
    return {
        type: types.WEB_SOCKET_DISCONNECTED,
    };
}

export function connectToWebSocket() {
    return function (dispatch: any, getState: () => AppState) {
        const isDev = process.env.NODE_ENV === 'development';
        const socketUrl = isDev ? `ws://localhost:9000` : `${window.location.hostname}`;

        const userData: UserData | undefined = getCurrentUser(getState());
        // const accessToken = getUserAccessToken(getState());
        const accessToken = '12345';

        return new Promise((resolve, reject) => {
            const socket = io(socketUrl, {
                query: {
                    userId: userData?.id,
                },
                transports: ['websocket'],
                path: isDev ? '' : '/api/socket.io',
                reconnection: false,
            });

            socket.on('connect', () => {
                // eslint-disable-next-line
                console.log('client success connected to rates websocket, socket.id = ', socket.id);

                socket.emit('authenticate', {accessToken});

                dispatch(connected({socket}));

                resolve(socket);
            });

            socket.on('disconnect', (reason: any) => {
                // eslint-disable-next-line
                console.log('client disconnected from websocket, reason: ', reason);
                dispatch(disconnected());
                const RECONNECT_TIMEOUT = 5000;
                window.setTimeout(() => dispatch(reconnect()), RECONNECT_TIMEOUT);
                reject(reason);
            });
        });
    };
}

export function receivedCreatedBid(newBid: Bid) {
    return function (dispatch: any, getState: () => AppState) {
        const userData: UserData | undefined = getCurrentUser(getState());
        dispatch({
            type: types.WEB_SOCKET_RECEIVED_CREATED_BID,
            payload: {
                newBid,
                currentUser: userData,
            },
        });
    };
}

export function receivedCreatedBidNote(newBid: Bid) {
    return function (dispatch: any, getState: () => AppState) {
        const userData: UserData | undefined = getCurrentUser(getState());
        dispatch({
            type: types.WEB_SOCKET_RECEIVED_CREATED_BID_NOTE,
            payload: {
                newBid,
                currentUser: userData,
            },
        });
    };
}

export function receivedCreatedUser(newUser: UserData) {
    return function (dispatch: any) {
        dispatch({
            type: types.WEB_SOCKET_RECEIVED_CREATED_USER,
            payload: {
                newUser,
            },
        });
    };
}

export function receivedRemoveUser(id: string) {
    return function (dispatch: any) {
        dispatch({
            type: types.WEB_SOCKET_RECEIVED_REMOVED_USER,
            payload: {
                id,
            },
        });
    };
}

export function receivedUpdateUser(updatedUser: UserData) {
    return function (dispatch: any) {
        dispatch({
            type: types.WEB_SOCKET_RECEIVED_UPDATED_USER,
            payload: {
                updatedUser,
            },
        });
    };
}

export function receivedCallBidNote(callBid: Bid) {
    return function (dispatch: any, getState: () => AppState) {
        const userData: UserData | undefined = getCurrentUser(getState());
        dispatch({
            type: types.WEB_SOCKET_RECEIVED_CALL_BID,
            payload: {
                callBid,
                currentUser: userData,
            },
        });
    };
}

export function receivedNotification(notification: Notification) {
    return function (dispatch: any) {
        dispatch({
            type: types.WEB_SOCKET_RECEIVED_NOTIFICATION,
            payload: {
                notification,
            },
        });
    };
}
