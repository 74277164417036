import {useDispatch} from 'react-redux';

import {init} from 'store/middlewares/websocket/actions';

const useInitWebsocketConnect = (): (() => void) => {
    const dispatch = useDispatch();
    return () => dispatch(init());
};

export default useInitWebsocketConnect;
