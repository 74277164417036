import {AppState, PaginationValues} from 'store/types';

import {Bid} from 'core/entities/Bid/types';
import {ConfirmedLine} from 'core/entities/ConfirmedLine/types';

import {BidImages, Filters, SearchParamsValues} from 'pages/Bids/types';

export const getBidsCurrentPagination = (state: AppState): PaginationValues => {
    return state.bidsPage.bidsPagination;
};

export const getConfirmedLinesCurrentPagination = (state: AppState): PaginationValues => {
    return state.bidsPage.confirmedLinesPagination;
};

export const getBidsPagination = (state: AppState): PaginationValues => {
    return state.bidsPage.bidsPagination;
};

export const getBidState = (state: AppState): Bid[] => {
    return state.bidsPage.bids;
};

export const getConfirmedLinesState = (state: AppState): ConfirmedLine[] => {
    return state.bidsPage.confirmedLines;
};

export const getUserId = (state: AppState): string | undefined => {
    return state.userData.user?.id;
};

export const getIsSearch = (state: AppState): boolean => state.bidsPage.isSearch;
export const getIsFiltered = (state: AppState): boolean => state.bidsPage.isFiltered;

export const getBidImages = (state: AppState): BidImages => {
    return state.bidsPage.bidImages;
};

export const getSearchParams = (state: AppState): SearchParamsValues => state.bidsPage.searchParams;

export const getFilters = (state: AppState): Filters => state.bidsPage.filtersParams;
