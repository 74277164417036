import {AxiosResponse} from 'axios';
import {RECEIVED_BID_BY_ID} from './bidsActionTypes';
import * as requests from './requests';
import {showErrorModal} from './modalActions';

export function receivedBidById(bidId: string): any {
    return function (dispatch: any): Promise<void> {
        return requests
            .receivedBidById(bidId)
            .then((response: AxiosResponse): void => {
                dispatch({
                    type: RECEIVED_BID_BY_ID,
                    payload: response.data,
                });
            })
            .catch(() => {
                dispatch(showErrorModal());
            });
    };
}
