import {useDispatch} from 'react-redux';
import {receivedBidById} from 'store/actions/bidActions';

interface OwnProps {
    receivedBidById: (bidId: string) => void;
}

export default function (): OwnProps {
    const dispatch = useDispatch();

    return {
        receivedBidById: (bidId: string) => dispatch(receivedBidById(bidId)),
    };
}
