import {modalNames} from '../../constants';
import AddNoteModal from './AddNoteModal';
import CreateBidModal from './CreateBidModal';
import SameBidFoundModal from './SameBidFoundModal';

const modalMap = {
    [modalNames.addBidNoteModal]: AddNoteModal,
    [modalNames.createBidModal]: CreateBidModal,
    [modalNames.sameBidFoundModal]: SameBidFoundModal,
};

export default modalMap;
