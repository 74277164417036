import moment from 'moment-timezone';
import {timeZone} from 'constants/index';

export const getDifferenceTimeFromNow = (date: Date | undefined): string | undefined => {
    if (!date) {
        return undefined;
    }

    const createDateByTimeZone = moment.tz(date, 'UTC').clone().tz(timeZone);
    const dateNow = moment.tz(new Date(), 'UTC').clone().tz(timeZone);

    const duration = moment.duration(dateNow.diff(createDateByTimeZone));

    const differenceDate = [
        duration.years() > 0 ? `${duration.years()} year(s)` : null,
        duration.months() > 0 ? `${duration.months()} month(s)` : null,
        duration.days() > 0 ? `${duration.days()} day(s)` : null,
        duration.hours() > 0 ? `${duration.hours()} hour(s)` : null,
        duration.minutes() > 0 ? `${duration.minutes()} minute(s)` : null,
        duration.seconds() > 0 ? `${duration.seconds()} second(s)` : null,
    ].filter((data: any): boolean => data);

    differenceDate.push(differenceDate.length > 0 ? 'ago' : 'just now');

    return differenceDate.join(' ');
};
