export const timeZone = 'America/New_York';
export const dateFormat = 'MM/DD/YY HH:mm:ss';
export const reportDateFormat = 'MM/DD/YY';
export const oneSecond = 1000;
// eslint-disable-next-line no-magic-numbers
export const oneMinute = 60 * oneSecond;
// eslint-disable-next-line no-magic-numbers
export const twoHours = oneMinute * 60 * 2;
export const spaceKeyCode = 32;
export const minPasswordLength = 8;
