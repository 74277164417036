import {CreateBidFormValues} from '../types';

const DEFAULT_RATE = 50;

export const initialCreateBidValues: CreateBidFormValues = {
    pickupCityLine: '',
    pickupAmount: 1,
    deliveryCityLine: '',
    deliveryAmount: 1,
    rate: DEFAULT_RATE,
    note: '',
    files: [],
};
