import {Bid} from 'core/entities/Bid/types';

export const RECEIVED_BID_BY_ID = 'RECEIVED_BID_BY_ID' as const;

export interface ReceivedBidById {
    type: typeof RECEIVED_BID_BY_ID;
    payload: Bid;
}

export type BidsGlobalActionTypes = ReceivedBidById;
