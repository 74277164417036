import React, {useEffect} from 'react';
import {notification} from 'antd';
import useSound from 'use-sound';

// @ts-ignore
import notificationSound from 'widgets/Notification/sound/notification.mp3';
import useNotificationsWidgetActions from 'hooks/useFindBidByIdActions';
import {NotificationMessage} from './types';
import useStateNotification from './hooks/useStateNotification';
import useStateIsSoundNotification from './hooks/useStateIsSoundNotification';
import ButtonLink from './components/ButtonLink';

const Notification = (): null => {
    const notificationData: NotificationMessage = useStateNotification();
    const isSound: boolean = useStateIsSoundNotification();
    const {receivedBidById} = useNotificationsWidgetActions();
    const [play] = useSound(notificationSound);
    useEffect(() => {
        if (notificationData.message.length) {
            notification.open({
                description: notificationData.description,
                message: notificationData.message,
                btn: notificationData.bidId && (
                    <ButtonLink handlePress={() => receivedBidById(notificationData.bidId as string)} />
                ),
            });
            if (isSound) {
                play();
            }
        }
    }, [notificationData]);
    return null;
};

export default Notification;
