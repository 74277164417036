import React, {useState} from 'react';
import {useHistory} from 'react-router';
import {useLocation} from 'react-router-dom';

import Text from 'antd/lib/typography/Text';
import Layout from 'antd/lib/layout';
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';
import {ExportOutlined, BellFilled} from '@ant-design/icons';

import useStateUserData from 'hooks/useStateUserData';
import useStateUserIsAdmin from 'hooks/useStateUserIsAdmin';

import {UserData} from 'core/entities/User/types';

import getUserFullNameWithTeamCode from 'core/entities/User/getUserFullNameWithTeamCode';
import config from 'config';

import Logo from './components/Logo';
import Notifications from './components/Notifications';
import useUserActions from './hooks/useUserActions';
import styles from './header.module.scss';

const Header = (): JSX.Element => {
    const wrapClasses = config.isCaravanTheme ? `${styles.wrap} ${styles.caravanWrap}` : styles.wrap;
    const [isOpenNotification, setIsOpenNotification] = useState<boolean>(false);
    const history = useHistory();
    const isAdmin = useStateUserIsAdmin();
    const location = useLocation();
    const user: UserData | undefined = useStateUserData();
    const {logout} = useUserActions();
    const iconStyle = {
        fontSize: '24px',
    };

    const handleOpenNotification = (): void => {
        setIsOpenNotification(!isOpenNotification);
    };

    return (
        <>
            <Layout.Header className={wrapClasses}>
                <Logo />

                {isAdmin && (
                    <Menu className={styles.menu} theme="dark" mode="horizontal" selectedKeys={[location.pathname]}>
                        <Menu.Item key="/bids" onClick={() => history.push('/bids')}>
                            Bids
                        </Menu.Item>
                        <Menu.Item key="/users" onClick={() => history.push('/users')}>
                            Users
                        </Menu.Item>
                        <Menu.Item key="/reports" onClick={() => history.push('/reports')}>
                            Reports
                        </Menu.Item>
                    </Menu>
                )}

                {!config.isCaravanTheme && (
                    <a
                        className={styles.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={config.RATES_CALCULATOR_URL}
                    >
                        Rates
                    </a>
                )}

                {config.isUseRulesLink && (
                    <a className={styles.link} target="_blank" rel="noopener noreferrer" href={config.RULES_URL}>
                        Rules
                    </a>
                )}

                <div className={styles.item}>
                    <Text>{user?.company}</Text>
                    <Text>{user && getUserFullNameWithTeamCode(user)}</Text>
                    <Button onClick={handleOpenNotification} type="primary" icon={<BellFilled style={iconStyle} />} />
                    <Button onClick={logout} type="primary" icon={<ExportOutlined style={iconStyle} />} />
                </div>
                <Notifications close={handleOpenNotification} isOpen={isOpenNotification} />
            </Layout.Header>
        </>
    );
};

export default Header;
