import * as types from 'store/actions/actionTypes';
import * as wsActionTypes from 'store/middlewares/websocket/actionTypes';
import {AppData} from 'store/types';

type AppDataActions = types.AppActionTypes | wsActionTypes.WsConnected | wsActionTypes.WsDisconnected;

const defaultState: AppData = {
    clientWebsocketID: undefined,
    auth: {
        isLoggedIn: false,
    },
    modalData: undefined,
    showLoaderSpinner: false,
};

export default (state = defaultState, action: AppDataActions): AppData => {
    switch (action.type) {
        case wsActionTypes.WEB_SOCKET_CONNECTED: {
            return {...state, clientWebsocketID: action.payload.socketID};
        }

        case wsActionTypes.WEB_SOCKET_DISCONNECTED: {
            return {...state, clientWebsocketID: undefined};
        }

        case types.SHOW_LOADER_SPINNER: {
            return {
                ...state,
                showLoaderSpinner: true,
            };
        }

        case types.HIDE_LOADER_SPINNER: {
            return {
                ...state,
                showLoaderSpinner: false,
            };
        }

        case types.SHOW_MODAL: {
            return {
                ...state,
                modalData: action.payload,
            };
        }

        case types.HIDE_MODAL: {
            return {
                ...state,
                modalData: undefined,
            };
        }

        default:
            return state;
    }
};
