import React from 'react';
import Table from 'antd/lib/table';
import Text from 'antd/lib/typography/Text';
import {Address, Bid} from 'core/entities/Bid/types';

import {getDateConvertedToDefaultTz} from 'services/date';

import CityLine from 'pages/Bids/components/common/CityLine';
import CallButton from './components/CallButton';
import styles from './bidContent.module.scss';

interface OwnProps {
    dataSource: Bid[];
    handleCallSameBid: (id: string) => void;
}

const BidContent = ({dataSource, handleCallSameBid}: OwnProps): JSX.Element | null => {
    const columns = [
        {
            title: 'TIME ADDED',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt: Date) => <Text>{getDateConvertedToDefaultTz(createdAt)}</Text>,
            width: 160,
        },
        {
            title: 'FROM',
            dataIndex: 'pickupAddress',
            key: 'from',
            render: (pickupAddress: Address) => (
                <Text>
                    <CityLine address={pickupAddress} />
                </Text>
            ),
            width: 160,
        },
        {
            title: 'TO',
            dataIndex: 'deliveryAddress',
            key: 'to',
            render: (deliveryAddress: Address) => (
                <Text>
                    <CityLine address={deliveryAddress} />
                </Text>
            ),
            width: 160,
        },
        {
            title: 'RATE',
            dataIndex: 'rate',
            key: 'rate',
            render: (rate: number): JSX.Element => <Text>${rate}</Text>,
            width: 100,
        },
        {
            title: 'ACTION',
            render: ({id: bidId, calledBy, createdBy: {id: createdByUserId}}: Bid) => (
                <CallButton
                    calledBy={calledBy}
                    bidId={bidId}
                    createdByUserId={createdByUserId as string}
                    onCallSameBid={handleCallSameBid}
                />
            ),
        },
    ];
    return (
        <div className={styles.wrapper}>
            <Text>
                {dataSource.length} the same bid(s) <Text className={styles.italicText}>(in last 2 hours)</Text>
            </Text>
            <Table
                className="custom-table"
                rowKey={(bid: Bid) => bid.id}
                bordered={true}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                scroll={{
                    y: 318,
                }}
            />
        </div>
    );
};

export default BidContent;
