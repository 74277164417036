import {useDispatch} from 'react-redux';
import {logout} from 'store/actions/authActions';

interface OwnProps {
    logout: () => void;
}

export default function (): OwnProps {
    const dispatch = useDispatch();

    return {
        logout: () => dispatch(logout()),
    };
}
