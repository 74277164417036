import {PaginationValues} from 'store/types';

export default (items: any): PaginationValues => {
    return {
        pageSize: 50,
        currentPage: 1,
        allItemsCount: items.length,
        allPagesCount: 1,
    };
};
