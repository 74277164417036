import * as types from 'store/actions/actionTypes';

import globalRegister from 'utils/globalRegister';

export function showLoaderSpinner() {
    return {
        type: types.SHOW_LOADER_SPINNER,
    };
}

export function hideLoaderSpinner() {
    return {
        type: types.HIDE_LOADER_SPINNER,
    };
}

export function showModal(params: any) {
    const {name, mode, onSubmit, handlers = {}, closeBeforeSubmit, data} = params;
    const allHandlersMap = {onSubmit, ...handlers};

    globalRegister.setHandlers(allHandlersMap);

    return {
        type: types.SHOW_MODAL,
        payload: {
            name,
            mode,
            data,
            closeBeforeSubmit,
        },
    };
}

export function hideModal() {
    globalRegister.removeHandlers();
    return {
        type: types.HIDE_MODAL,
    };
}
