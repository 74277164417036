import React, {useEffect} from 'react';
import List from 'antd/lib/list';
import Text from 'antd/lib/typography/Text';
import {Notification} from 'core/entities/Notification/types';
import useNotificationsActions from 'components/layout/header/hooks/useNotificationsActions';
import useStateNotifications from 'components/layout/header/hooks/useStateNotifications';

import styles from './notificationList.module.scss';
import ListItem from './components/ListItem';

const NotificationList = (): JSX.Element => {
    const {fetchNotifications, removeNotification} = useNotificationsActions();
    const notifications = useStateNotifications();

    useEffect(() => {
        fetchNotifications();
    }, []);
    return (
        <List
            className={styles.wrap}
            itemLayout="horizontal"
            dataSource={notifications || []}
            renderItem={(item: Notification) => (
                <List.Item key={item.id} className={styles.notification_container}>
                    <List.Item.Meta
                        title={<Text strong>{item.title}</Text>}
                        description={<ListItem notification={item} removeNotification={removeNotification} />}
                    />
                </List.Item>
            )}
            rowKey={(record) => record.id as string}
        />
    );
};

export default NotificationList;
