import {UserData} from 'core/entities/User/types';
import * as requests from './requests';
import * as types from '../actionTypes';

export function fetchUserList() {
    return function (dispatch: any) {
        requests
            .fetchUserList()
            .then((response) => response.data)
            .then((response) => {
                dispatch({
                    type: types.LIST_USERS_RECEIVED,
                    payload: response,
                });
            })
            .catch((err) => console.error(err));
    };
}

export function searchUsers(search: string) {
    return function (dispatch: any) {
        if (!search || search.length === 0) {
            dispatch(fetchUserList());
            return;
        }
        requests
            .searchUsers(search)
            .then((response) => response.data)
            .then((data) => {
                dispatch({
                    type: types.LIST_USERS_SEARCH,
                    payload: data,
                });
            })
            .catch((err) => console.error(err));
    };
}

export function createUser(user: UserData) {
    return function () {
        return new Promise((resolve, reject) => requests.createUser(user).then(resolve).catch(reject));
    };
}

export function updateUser(user: UserData) {
    return function () {
        return new Promise((resolve, reject) => requests.updateUser(user).then(resolve).catch(reject));
    };
}

export function deleteUser(id: string) {
    return function () {
        requests.deleteUser(id).catch((err) => console.error(err));
    };
}

export function changeUsersNumberPage(currentPage: number) {
    return function (dispatch: any) {
        dispatch({
            type: types.CHANGE_USERS_PAGE,
            payload: {
                currentPage,
            },
        });
    };
}
