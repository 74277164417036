export const modalNames = {
    addBidNoteModal: 'ADD_BID_NOTE_MODAL',
    createBidModal: 'CREATE_A_BID_MODAL',
    sameBidFoundModal: 'SAME_BID_FOUND_BID_MODAL',
};

export const bidListFrom = 220;
export const bidListTo = 220;
export const bidListRate = 90;
export const bidListDate = 150;
export const bidListCalls = 150;
export const bidListCompany = 200;
export const bidListUser = 200;

export const confirmedLineFrom = 270;
export const confirmedLineTo = 270;
export const confirmedLineMiles = 120;
export const confirmedLineRate = 120;
export const confirmedLineDetails = 550;
export const confirmedLineAction = 193;

export const confirmedLinePageSize = 3;

export const callBidTooltip = `can't call own bid`;
export const calledBidTooltip = `bid was called by you`;

export const MIN_STOPS_COUNT = 1;
export const MAX_STOPS_COUNT = 99;

export const MIN_RATE_AMOUNT = 1;
export const MAX_RATE_AMOUNT = 99999;

export const TEXT_FIELD_LENGTH = 144;

export const rateStep = 50;

export const deletedUserPlaceholder = 'user deleted';

export const fileErrorResponse = 'Bad file type';
export const largeFileErrorResponse = 'File too large';
export const statusCodeLargeFile = 413;
export const fileErrorMessage = 'The file could not be uploaded, please try again later.';
export const fileErrorTitle = 'File Error';

export const LOCATION_REQUIRED_MESSAGE = 'enter location';
export const LOCATION_REQUIRED_CITY = 'enter correct location city';
export const LOCATION_REQUIRED_STATE = 'enter correct location state';
export const LETTERS_REQUIRED_MESSAGE = 'location must contain letters';
export const LETTERS = /[a-zA-Z]+/;
