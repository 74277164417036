import {CreateBidFormValues} from '../types';
import * as actionsTypes from '../actionTypes';

export const setValuesToCreateBidModal = (
    createBidValues: CreateBidFormValues,
): actionsTypes.SetValuesToCreateBidModal => ({
    type: actionsTypes.SET_VALUES_TO_CREATE_BID_MODAL,
    payload: createBidValues,
});

export const clearValuesCreateBidModal = (): actionsTypes.ClearValuesCreateBidModal => ({
    type: actionsTypes.CLEAR_VALUES_CREATE_BID_MODAL,
});
