import React from 'react';
import {Address} from 'core/entities/Bid/types';
import {getState} from 'pages/Bids/utils';

const CityLine: React.FC<{address: Address}> = ({address}) => {
    const state = getState(address.state);
    return (
        <>
            {address.city}, {state?.title || address.state}
        </>
    );
};

export default CityLine;
